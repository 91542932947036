@import url('https://fonts.googleapis.com/css2?family=Raleway');

:root{
  --cloud-speed: 40s;
  --background: #FF9770;
  --background-cloud: #FFE1D6;
  --background-title: #83C5BE;
  --background-card: #00000000;
  --background-logo: #FFCBB8;
  --background-button: #ffb194;
  --background-button-hover: #fda88999;
  --content-start: calc(100vh + 100px);
}

body {
  font-family: 'Raleway', sans-serif;
}

.background{
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.cloud {
  position: absolute;
  height: 50px;
  width: 400px;
  left: -500px;
  border-radius: 100px;
  background: var(--background-cloud);
  animation-name: cloudmove-right;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  box-shadow: 0px 10px 40px 0px #00000033;
}

.cloudSmall{
  width: 250px;
  margin-top: -40px;
  box-shadow: none;
}

.cloudSmall.left {
  left: 50px;
}

.cloudSmall.right {
  left: 100px;
}

.cloudSmall.mid {
  left: 75px;
}

.cloudSlow{
  animation-duration: calc(var(--cloud-speed) + 10s);
}

.cloudMed{
  animation-duration: calc(var(--cloud-speed) + 5s);
}

.cloudFast{
  animation-duration: calc(var(--cloud-speed));
}

.cloudHigh{
  margin-top: calc(100vh * 0.3);
  animation-delay: 0s;
}

.cloudMid{
  margin-top: calc(100vh * 0.5);
  z-index: 1;
  animation-name: cloudmove-left;
  animation-delay: 1s;
  left: auto;
  right: -500px;
}

.cloudLow{
  margin-top: calc(100vh * 0.7);
  animation-delay: 2s;
}

.logo {
  position: absolute;
  margin-top: calc(100vh * 0.5 - 75px);
  left: calc(100vw * 0.5 - 75px);
  height: 150px;
  width: 150px;
  border-radius: 150px;
  background-color: var(--background-logo);
  box-shadow: 0px 0px 20px 0px #00000033;
}

.logo > div {
  position: relative;
  height: 100%;
  width: 100%;
}

.logo > div > h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #66514b;
  letter-spacing: -5px;
  font-weight: bold;
}

.content {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  z-index: 2;
}

.content::-webkit-scrollbar {
  display: none;
}

.contentItem {
  width: 80%;
  margin-left: 10vw;
  background-color: var(--background-card);
  margin-top: 20vh;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px;
}

.contentItem > div {
  width: 50%;
}

.contentItem:first-child {
  margin-top: var(--content-start);
}

.contentItem:nth-child(even){
  flex-direction: row-reverse;
}

.contentItem > div {
  position: relative;
}

.contentItem:nth-child(odd) > div > .content-title{
  right: auto;
  left: 90px;
}

.contentTitle {
  position: absolute;
  background: var(--background-cloud);
  z-index: 10;
  border-radius: 100vw;
  padding: 0 40px;
  width: 400px;
  min-width: 500px;
  box-shadow: 0px -10px 20px -5px #00000033;
  right: 90px;
}

.contentBody {
  position: absolute;
  z-index: 9;
  padding: 20px 30px;
  min-width: 560px;
  width: 100%;
  background: var(--background-cloud);
  border-radius: 100vw;
  box-shadow: 0px 0px 40px 0px #00000033;
  top: 55px;
}

.contentLink {
  border-radius: 100vw;
  width: fit-content;
  overflow: hidden;
  margin-left: auto;
  margin-right: 40px;
  margin-top: 10px;
}

.spacer {
  height: calc(100vh + 500px);
}

.contentLink > a {
  background: var(--background-button);
  color: #FFFFFFdd;
}

.contentLink > a:hover {
  background: var(--background-button-hover);
  color: #FFFFFF77;
}

.contentLink > a > p {
  font-weight: bold;
}

.watermark {
  position: absolute;
  bottom: 0;
  left: 10px;
  color: #00000088;
  z-index: 3;
  user-select: none;
}

.watermark > p::before {
  content: '\00A9\2800';
}

.links {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
}

.links > a {
  position: relative;
}

.links > a > img {
  width: 30px;
  opacity: 0.5;
}

.links > a:hover {
  cursor: pointer;
}

@media only screen and (max-height: 900px){
  .cloudHigh{
    margin-top: calc(100vh * 0.2);
  }
  
  .cloudMid{
    margin-top: calc(100vh * 0.5);
  }
  
  .cloudLow{
    margin-top: calc(100vh * 0.8);
  }
}

@media only screen and (max-width: 1500px){
  .contentItem > div {
    width: auto;
  }

  .contentItem{
    display: block;
    max-width: 900px;
    margin: 20vh auto 0 auto;
  }
}

@media only screen and (max-width: 800px){
  .contentTitle{
    right: auto;
    left: 90px;
    min-width: auto;
  }

  .contentBody{
    min-width: auto;
  }

  .contentItem{
    width: 100%
  }
}

@media only screen and (max-width: 600px){
  .contentTitle{
    position: relative;
    right: auto;
    left: auto;
    margin: 0;
    padding: 0;
    width: auto;
    background: none;
    box-shadow: none;
  }

  .contentItem:nth-child(odd) > div > .contentTitle{
    right: auto;
    left: auto;
  }

  .contentBody{
    position: relative;
    top: auto;
    width: auto;
    min-width: auto;
    background: none;
    box-shadow: none;
  }

  .contentItem{
    width: 100%;
    border-radius: 0;
    background-color: var(--background-cloud);
    padding: 20px;
    box-shadow: 0px 0px 40px 0px #00000033;
  }
}

@keyframes cloudmove-right{
  from {
    left: -500px;
  }

  to {
    left: calc(100vw + 100px)
  }
}

@keyframes cloudmove-left{
  from {
    right: -500px;
  }

  to {
    right: calc(100vw + 100px)
  }
}